import { styled } from '@mui/system'
import BHLink from '../../molecules/link'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

const Root = styled(MuiBreadcrumbs)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	color: ${({ theme }) => theme.palette.grey.breadcrumbs};
	padding-bottom: ${({ theme }) => theme.spacing(2)};
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 10px;
		margin-bottom: 10px;
	}
`

const LinkCurrent = styled(BHLink)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	color: ${({ theme }) => theme.palette.grey.breadcrumbs};
`

const LinkPrev = styled(BHLink)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	color: ${({ theme }) => theme.palette.grey.breadcrumbs};
`

export { LinkCurrent, LinkPrev, Root }
